import React, { useEffect, useMemo, useState } from "react";
import commonService from "../../services/CommonService";
import { GetAvailableFilters } from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import SysModels from "../../models";
import systemStore, { useCurrentCustomer } from "../../stores/SystemStore";
import { reaction } from "mobx";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import SysServices from "../../services";
import FormModal from "../../components/common/FormModal";
import DocEditor from "../../components/common/DocEditor";

function ChatBot(props: any) {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<SysModels.ILookupIntDto[]>([]);
  const [loginStatus, setLoginStatus] = useState(systemStore.loginStatus);
  const [mwfRole, setMwfRole] = useState(systemStore.extension_MWFRole);

  const isCustomerSite = () => {
    return !!(
      !commonService.isEmployeeSite ||
      (commonService.isEmployeeSite && currentCustomer?.id)
    );
  };

  const additionalFilters = () => {
    if (
      isCustomerSite() &&
      filters.find((f) => f.value === SysModels.MartenTypeEnum.HowTos)
    ) {
      return [
        {
          value: SysModels.MartenTypeEnum.HowTos, //9999,
          label: "How To's",
        },
      ];
    }
    if (
      filters.find(
        (f) => f.value === SysModels.MartenTypeEnum.PortalDocumentations
      )
    ) {
      return [
        {
          value: SysModels.MartenTypeEnum.PortalDocumentations,
          label: "Portal Documentations",
        },
      ];
    }
    return [];
  };

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.loginStatus,
      (val, prevVal, r) => {
        setLoginStatus(val);
      }
    );
    const disposer2 = reaction(
      () => systemStore.extension_MWFRole,
      (n, p, r) => {
        setMwfRole(n);
      }
    );

    setFilters(GetAvailableFilters());

    return () => {
      disposer();
      disposer2();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (open) {
      commonService.focusInput(inputId, 500);
    } else {
      document.getElementById("chatbox-filters")?.classList?.add("hide");
      setTimeout(() => {
        document.getElementById("chatbox-filters")?.classList?.remove("hide");
      }, 600);
    }
  }, [open]);

  const currentCustomer = useCurrentCustomer();
  useEffect(() => {
    setSearch("");
    gridAllOthers.setData(undefined);
    gridInvoices.setData(undefined);
    gridOrders.setData(undefined);
    gridHowTo.setData(undefined);
    gridPortalDocs.setData(undefined);
    setFilters(GetAvailableFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomer]);

  const [showDdResult, setShowDdResult] = useState(false);
  const [search, setSearch] = useState("");

  const gridAllOthers = useFetchHelper(async () => {
    const list = filters.map((x) => x.value);
    const searchData: SysModels.IGlobalSearchInputDto = {
      query: search,
      isCreditMemos: list.includes(SysModels.MartenTypeEnum.CreditMemos),
      isCustomers: list.includes(SysModels.MartenTypeEnum.Customers),
      isInventoryItems: list.includes(SysModels.MartenTypeEnum.InventoryItems),
      isInvoices: false,
      isOrders: false,
      isHowTos: false,
      isPortalDocumentations: list.includes(
        SysModels.MartenTypeEnum.PortalDocumentations
      ),
    };
    return SysServices.http.search.globalSearchPreview(searchData);
  }, "Search Results");

  const gridInvoices = useFetchHelper(async () => {
    const searchData: SysModels.IGlobalSearchInputDto = {
      query: search,
      isCreditMemos: false,
      isCustomers: false,
      isInventoryItems: false,
      isInvoices: true,
      isOrders: false,
      isHowTos: false,
      isPortalDocumentations: false,
    };
    return SysServices.http.search.globalSearchPreview(searchData);
  }, "Search Invoices");

  const gridOrders = useFetchHelper(async () => {
    const searchData: SysModels.IGlobalSearchInputDto = {
      query: search,
      isCreditMemos: false,
      isCustomers: false,
      isInventoryItems: false,
      isInvoices: false,
      isOrders: true,
      isHowTos: false,
      isPortalDocumentations: false,
    };
    return SysServices.http.search.globalSearchPreview(searchData);
  }, "Search Orders");

  const gridHowTo = useFetchHelper(async () => {
    const searchData: SysModels.IGeneralDocumentationSearchDto = {
      onlyPublished: true,
      orderByEnum: SysModels.OrderByEnum.Ascending,
      search: search,
    };
    return SysServices.http.generalDocumentation.list(1, 5, searchData);
  }, "How To's");

  const gridPortalDocs = useFetchHelper(async () => {
    return SysServices.http.siteDocumentation.getPublishedItems(
      {
        page: 1,
        pageSize: 5,
        search: search,
        orderByEnum: SysModels.OrderByEnum.Ascending,
      },
      true
    );
  }, "Portal Documentations");

  const [queStarted, setQueStarted] = useState(false);

  const clearGrids = () => {
    gridAllOthers.setData(undefined);
    gridInvoices.setData(undefined);
    gridOrders.setData(undefined);
    gridHowTo.setData(undefined);
    gridPortalDocs.setData(undefined);

    gridAllOthers.setStatus(FetchStatus.Default);
    gridInvoices.setStatus(FetchStatus.Default);
    gridOrders.setStatus(FetchStatus.Default);
    gridHowTo.setStatus(FetchStatus.Default);
    gridPortalDocs.setStatus(FetchStatus.Default);
  };

  const validateSearch = () => {
    const list = filters.map((x) => x.value);
    gridAllOthers.setData(undefined);
    gridInvoices.setData(undefined);
    gridOrders.setData(undefined);
    gridHowTo.setData(undefined);
    gridPortalDocs.setData(undefined);

    const que: { getData: () => Promise<any> }[] = [];

    if (
      additionalFilters().find(
        (x) => x.value === SysModels.MartenTypeEnum.HowTos
      )
    ) {
      que.push(gridHowTo);
    }
    if (
      additionalFilters().find(
        (x) => x.value === SysModels.MartenTypeEnum.PortalDocumentations
      )
    ) {
      que.push(gridPortalDocs);
    }

    if (
      list.includes(SysModels.MartenTypeEnum.CreditMemos) ||
      list.includes(SysModels.MartenTypeEnum.Customers) ||
      list.includes(SysModels.MartenTypeEnum.InventoryItems)
    ) {
      //gridAllOthers.getData();
      que.push(gridAllOthers);
    }
    if (list.includes(SysModels.MartenTypeEnum.Invoices)) {
      //gridInvoices.getData();
      que.push(gridInvoices);
    }
    if (list.includes(SysModels.MartenTypeEnum.SalesOrders)) {
      //gridOrders.getData();
      que.push(gridOrders);
    }

    setQueStarted(true);
    setHas408({ other: false, order: false, invoice: false });
    const callData = (i: number) => {
      if (que[i]) {
        que[i].getData().finally(() => {
          callData(i + 1);
        });
      } else {
        setQueStarted(false);
      }
    };
    callData(0);
  };

  const doSearch = () => {
    if (props.forPage && props.onSearch) {
      props.onSearch(search);
      return null;
    }
    const tmo = setTimeout(() => {
      if (search) {
        validateSearch();
      } else {
        clearGrids();
      }
    }, 500);
    return tmo;
  };

  const doFilter = () => {
    if (props.forPage && props.onFilter) {
      props.onFilter(filters);
      return null;
    }
    const tmo = setTimeout(() => {
      if (search) {
        validateSearch();
      } else {
        clearGrids();
      }
    }, 1000);
    return tmo;
  };

  useEffect(() => {
    const tmo = doSearch();
    return () => {
      tmo && clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const tmo = doFilter();
    return () => {
      tmo && clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const getDescription = (item: SysModels.IGlobalSearchOutputDto) => {
    if (
      item.searchType === SysModels.MartenTypeEnum.Customers ||
      item.searchType === SysModels.MartenTypeEnum.InventoryItems
    ) {
      return `${item.name} (${item.id})`;
    }
    if (
      item.searchType === SysModels.MartenTypeEnum.HowTos ||
      item.searchType === SysModels.MartenTypeEnum.PortalDocumentations
    ) {
      return `${item.name}`;
    }
    return `${item.id} (${item.name})`;
  };

  const isUnderMaintenance = (type: SysModels.MartenTypeEnum) => {
    if (!filters.find((f) => f.value === type)) {
      return false;
    }
    if (type === SysModels.MartenTypeEnum.CreditMemos) {
      return gridAllOthers.data?.downSearchesDto?.isCreditMemoDown || false;
    }
    if (type === SysModels.MartenTypeEnum.Customers) {
      return gridAllOthers.data?.downSearchesDto?.isCustomerDown || false;
    }
    if (type === SysModels.MartenTypeEnum.InventoryItems) {
      return gridAllOthers.data?.downSearchesDto?.isInventoryItemsDown || false;
    }
    if (type === SysModels.MartenTypeEnum.Invoices) {
      return gridInvoices.data?.downSearchesDto?.isInvoiceDown || false;
    }
    if (type === SysModels.MartenTypeEnum.SalesOrders) {
      return gridOrders.data?.downSearchesDto?.isSalesOrdersDown || false;
    }
    return false;
  };

  const mainGrid = useMemo(() => {
    return [
      ...(gridAllOthers.data?.globalSearchOutputDtos || []),
      ...(gridInvoices.data?.globalSearchOutputDtos || []),
      ...(gridOrders.data?.globalSearchOutputDtos || []),
      ...(gridHowTo.data?.generalDocumentationDisplayOutputDtos || []).map(
        (item) => {
          const data: SysModels.IGlobalSearchOutputDto = {
            id: item.sqlId,
            sqlId: Math.random() * 9999,
            searchType: SysModels.MartenTypeEnum.HowTos, //9999
            name: `${item.title} (${item.subject})`,
            customerNumber: "",
          };
          return data;
        }
      ),
      ...(gridPortalDocs.data?.siteDocumentationDisplayOutputDtos || []).map(
        (item) => {
          const data: SysModels.IGlobalSearchOutputDto = {
            id: item.sqlId,
            sqlId: Math.random() * 9999,
            searchType: SysModels.MartenTypeEnum.PortalDocumentations,
            name: `${item.title} (${item.subject})`,
            customerNumber: "",
          };
          return data;
        }
      ),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gridAllOthers.data,
    gridInvoices.data,
    gridOrders.data,
    gridHowTo.data,
    gridPortalDocs.data,
  ]);

  const loadingStatus = useMemo(() => {
    if (
      gridAllOthers.status === FetchStatus.InProgress ||
      gridInvoices.status === FetchStatus.InProgress ||
      gridOrders.status === FetchStatus.InProgress ||
      gridHowTo.status === FetchStatus.InProgress ||
      gridPortalDocs.status === FetchStatus.InProgress
    ) {
      return FetchStatus.InProgress;
    }
    if (
      gridAllOthers.status === FetchStatus.Failed ||
      gridInvoices.status === FetchStatus.Failed ||
      gridOrders.status === FetchStatus.Failed ||
      gridHowTo.status === FetchStatus.Failed ||
      gridPortalDocs.status === FetchStatus.Failed
    ) {
      return FetchStatus.Failed;
    }
    if (
      gridAllOthers.status === FetchStatus.Complete ||
      gridInvoices.status === FetchStatus.Complete ||
      gridOrders.status === FetchStatus.Complete ||
      gridHowTo.status === FetchStatus.Complete ||
      gridPortalDocs.status === FetchStatus.Complete
    ) {
      return FetchStatus.Complete;
    }
    return FetchStatus.Default;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gridAllOthers.status,
    gridInvoices.status,
    gridOrders.status,
    gridHowTo.status,
    gridPortalDocs.status,
  ]);

  const [has408, setHas408] = useState({
    other: false,
    invoice: false,
    order: false,
  });

  useEffect(() => {
    setHas408((p) => {
      return { ...p, other: gridAllOthers.error?.code === 408 };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridAllOthers.error]);

  useEffect(() => {
    setHas408((p) => {
      return { ...p, invoice: gridInvoices.error?.code === 408 };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridInvoices.error]);

  useEffect(() => {
    setHas408((p) => {
      return { ...p, order: gridOrders.error?.code === 408 };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridOrders.error]);

  const isInQue = (stat: FetchStatus) => {
    return (
      queStarted && stat !== FetchStatus.Complete && stat !== FetchStatus.Failed
    );
  };

  const [inputId] = useState(commonService.getUniqueId());
  const [howTo, setHowTo] = useState<SysModels.IGlobalSearchOutputDto>();
  const getHowToconent = () => {
    if (howTo) {
      return gridHowTo.data?.generalDocumentationDisplayOutputDtos?.find(
        (x) => x.sqlId === howTo.id
      );
    }
    return undefined;
  };

  const [portalDoc, setPortalDoc] =
    useState<SysModels.IGlobalSearchOutputDto>();
  const getPortalDoc = () => {
    if (portalDoc) {
      return gridPortalDocs.data?.siteDocumentationDisplayOutputDtos?.find(
        (x) => x.sqlId === portalDoc.id
      );
    }
    return undefined;
  };

  return (
    <>
      {howTo && (
        <FormModal
          title={getHowToconent()?.title || ""}
          isOpen={true}
          close={() => {
            setHowTo(undefined);
          }}
          submit={(e) => {
            setHowTo(undefined);
          }}
          primaryButtonOnly={true}
          submitButtonLabel="Done"
        >
          <div>
            <div className="px-4 py-3">
              <h4>{getHowToconent()?.subject || ""}</h4>
              <hr />
              <DocEditor
                readonly={true}
                content={
                  gridHowTo.data?.generalDocumentationDisplayOutputDtos?.find(
                    (x) => x.sqlId === howTo.id
                  )?.content || ""
                }
              ></DocEditor>
            </div>
          </div>
        </FormModal>
      )}
      {portalDoc && (
        <FormModal
          title={getPortalDoc()?.title || ""}
          isOpen={true}
          close={() => {
            setPortalDoc(undefined);
          }}
          submit={(e) => {
            setPortalDoc(undefined);
          }}
          primaryButtonOnly={true}
          submitButtonLabel="Done"
        >
          <div>
            <div className="px-4 py-3">
              <h4>{getPortalDoc()?.subject || ""}</h4>
              <hr />
              <DocEditor
                readonly={true}
                content={
                  gridPortalDocs.data?.siteDocumentationDisplayOutputDtos?.find(
                    (x) => x.sqlId === portalDoc.id
                  )?.content || ""
                }
              ></DocEditor>
            </div>
          </div>
        </FormModal>
      )}
      <div
        id="chatbox-overlay"
        className={`${open ? "open" : ""}`}
        onClick={(e) => {
          setOpen(false);
        }}
      ></div>
      <div id="chatbox-filters" className={`${open ? "open" : ""}`}>
        {GetAvailableFilters().map((f) => (
          <span
            key={f.value}
            onClick={(e) => {
              setFilters((p) => {
                if (!p.find((ff) => f.value === ff.value)) {
                  return [...p, f];
                }
                return p.filter((p) => p.value !== f.value);
              });
            }}
          >
            {f.label}{" "}
            <i
              className={`fa ${
                filters.find((ff) => f.value === ff.value)
                  ? "fa-check-square"
                  : "fa-square-o"
              }`}
              style={{ fontWeight: "normal" }}
            ></i>
          </span>
        ))}
        <hr className="my-1" />
        <div>
          <div className={`${showDdResult ? "show" : ""}`}>
            {loadingStatus === FetchStatus.Complete && !mainGrid.length && (
              <div className="result-header">
                <small>No Result(s)</small>
              </div>
            )}

            {filters.length === 0 && (
              <div className="result-header disabled">Pick a filter...</div>
            )}

            {!!additionalFilters().find(
              (x) => x.value === SysModels.MartenTypeEnum.HowTos
            ) &&
              (isInQue(gridHowTo.status) ||
                gridHowTo.status === FetchStatus.InProgress ||
                (gridHowTo.status === FetchStatus.Default &&
                  search.length > 0)) && (
                <>
                  <div className="result-header disabled">
                    <small>How To's</small>
                  </div>
                  <div className="result-header">
                    <small>Loading...</small>
                  </div>
                </>
              )}
            {!!additionalFilters().find(
              (x) => x.value === SysModels.MartenTypeEnum.PortalDocumentations
            ) &&
              (isInQue(gridPortalDocs.status) ||
                gridPortalDocs.status === FetchStatus.InProgress ||
                (gridPortalDocs.status === FetchStatus.Default &&
                  search.length > 0)) && (
                <>
                  <div className="result-header disabled">
                    <small>Portal Documentations</small>
                  </div>
                  <div className="result-header">
                    <small>Loading...</small>
                  </div>
                </>
              )}

            {!!filters.find(
              (x) => x.value === SysModels.MartenTypeEnum.Invoices
            ) && (
              <>
                {(isInQue(gridInvoices.status) ||
                  gridInvoices.status === FetchStatus.InProgress ||
                  (gridInvoices.status === FetchStatus.Default &&
                    search.length > 0)) && (
                  <>
                    <div className="result-header disabled">
                      <small>Invoices</small>
                    </div>
                    <div className="result-header">
                      <small>Loading...</small>
                    </div>
                  </>
                )}
                {has408.invoice && (
                  <>
                    <div className="result-header disabled">
                      <small>Invoices</small>
                    </div>
                    <div className="result-header">
                      <div className="flex flex-row">
                        <small className="flex-1 text-danger">
                          Server is busy...
                        </small>
                        <div
                          className="pointer"
                          onClick={(e) => {
                            setHas408((p) => {
                              return { ...p, invoice: false };
                            });
                            gridInvoices.getData();
                          }}
                        >
                          <i className="fa fa-refresh text-primary"></i>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            {!!filters.find(
              (x) => x.value === SysModels.MartenTypeEnum.SalesOrders
            ) && (
              <>
                {(isInQue(gridOrders.status) ||
                  gridOrders.status === FetchStatus.InProgress ||
                  (gridOrders.status === FetchStatus.Default &&
                    search.length > 0)) && (
                  <>
                    <div className="result-header disabled">
                      <small>Sales Orders</small>
                    </div>
                    <div className="result-header">
                      <small>Loading...</small>
                    </div>
                  </>
                )}
                {has408.order && (
                  <>
                    <div className="result-header disabled">
                      <small>Sales Orders</small>
                    </div>
                    <div className="result-header">
                      <div className="flex flex-row">
                        <small className="flex-1 text-danger">
                          Server is busy...
                        </small>
                        <div
                          className="pointer"
                          onClick={(e) => {
                            setHas408((p) => {
                              return { ...p, order: false };
                            });
                            gridOrders.getData();
                          }}
                        >
                          <i className="fa fa-refresh text-primary"></i>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            {filters.filter((x) =>
              [
                SysModels.MartenTypeEnum.InventoryItems,
                SysModels.MartenTypeEnum.CreditMemos,
                SysModels.MartenTypeEnum.Customers,
              ].includes(x.value)
            ).length > 0 && (
              <>
                {(isInQue(gridAllOthers.status) ||
                  gridAllOthers.status === FetchStatus.InProgress ||
                  (gridAllOthers.status === FetchStatus.Default &&
                    search.length > 0)) && (
                  <>
                    <div className="result-header disabled">
                      <small>Others</small>
                    </div>
                    <div className="result-header">
                      <small>Loading...</small>
                    </div>
                  </>
                )}
                {has408.other && (
                  <>
                    <div className="result-header disabled">
                      <small>Others</small>
                    </div>
                    <div className="result-header">
                      <div className="flex flex-row">
                        <small className="flex-1 text-danger">
                          Server is busy...
                        </small>
                        <div
                          className="pointer"
                          onClick={(e) => {
                            setHas408((p) => {
                              return { ...p, other: false };
                            });
                            gridAllOthers.getData();
                          }}
                        >
                          <i className="fa fa-refresh text-primary"></i>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            {loadingStatus === FetchStatus.Failed &&
              !has408.invoice &&
              !has408.order &&
              !has408.other && (
                <div
                  className="result-header pointer"
                  onClick={(e) => {
                    validateSearch();
                  }}
                >
                  <small className="text-danger">Please Try Again</small>
                </div>
              )}
            {loadingStatus === FetchStatus.Default && search.length === 0 && (
              <div className="result-header">
                <small>Start Typing</small>
              </div>
            )}

            {!!mainGrid.length && ( //gridAll.status === FetchStatus.Complete &&
              <>
                {[
                  ...additionalFilters(),
                  ...filters
                    .filter((x) =>
                      mainGrid?.find(
                        (i) =>
                          !additionalFilters().find(
                            (y) => y.value == x.value
                          ) &&
                          (isUnderMaintenance(x.value) ||
                            (i.searchType === x.value &&
                              !commonService.isNullOrWhitespace(i.name)))
                      )
                    )
                    .sort(commonService.sortByStringProperty("label")),
                ].map((x) => (
                  <React.Fragment key={x.value}>
                    <div className="result-header disabled">
                      <small>{x.label}</small>
                    </div>
                    {isUnderMaintenance(x.value) && (
                      <div className="result-header disabled text-danger">
                        <small>Global Search Under Maintenance</small>
                      </div>
                    )}
                    {mainGrid
                      ?.filter(
                        (r) =>
                          r.searchType === x.value &&
                          !commonService.isNullOrWhitespace(r.name)
                      )
                      ?.map((r) => (
                        <div
                          key={r.id}
                          className="pointer"
                          style={{
                            fontSize: "14px",
                          }}
                          onClick={(e) => {
                            if (x.value === SysModels.MartenTypeEnum.HowTos) {
                              setHowTo(r);
                            } else if (
                              x.value ===
                              SysModels.MartenTypeEnum.PortalDocumentations
                            ) {
                              setPortalDoc(r);
                            } else {
                              systemStore.setSearchedItemToPreview(r);
                            }
                          }}
                        >
                          {getDescription(r)}
                        </div>
                      ))}
                  </React.Fragment>
                ))}
              </>
            )}
            {loadingStatus === FetchStatus.Complete && !!mainGrid?.length && (
              <>
                <div className="result-divider"></div>
                <div
                  className="pointer"
                  onClick={(e) => {
                    if (GetAvailableFilters().length === filters.length) {
                      navigate(`/Search/${encodeURI(search)}`);
                    } else {
                      navigate(
                        `/Search/${encodeURI(search)}/${filters
                          .map((t) => t.value)
                          .sort()
                          .join(",")}`
                      );
                    }
                    setOpen(false);
                  }}
                >
                  <small>View All...</small>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div id="floating-chatbox" className={`${open ? "open" : ""}`}>
        <div
          className="chatbox-icon"
          title="Help?"
          onClick={(e) => {
            setOpen((p) => !p);
          }}
        >
          <i className="fa fa-question-circle"></i>
        </div>
        <div className="chatbox-input">
          <input
            type="text"
            className="form-control"
            placeholder="Looking for something?"
            autoComplete="new-password"
            id={inputId}
            onBlur={(e) => {
              //setOpen(false);
            }}
            onFocus={(e) => setShowDdResult(true)}
            value={search}
            onChange={(e) => {
              const val = `${e.target.value || ""}`;
              setSearch(val);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default ChatBot;
