import React, { useEffect } from "react";
import CustomerInfoDialog from "./CustomerInfoDialog";
import systemStore, { usePreviewSearchedItem } from "../stores/SystemStore";
import SysModels from "../models";
import InvoiceViewDialog from "./invoices/InvoiceViewDialog";
import CreditMemoViewDialog from "./invoices/CreditMemoViewDialog";
import OrderDetailDialog from "./orders/OrderDetailDialog";
import CatalogItemDialog from "./catalog/CatalogItemDialog";
import commonService from "../services/CommonService";
import ViewHowToDialog from "./widgets/ViewHowToDialog";

function GlobalSearchItemViewer(props: any) {
  const item = usePreviewSearchedItem();
  useEffect(() => {
    console.log(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <>
      {!!item && item.searchType === SysModels.MartenTypeEnum.Customers && (
        <CustomerInfoDialog
          key={item.id}
          id={item.id}
          onClose={() => {
            systemStore.setSearchedItemToPreview(undefined);
          }}
        ></CustomerInfoDialog>
      )}
      {!!item && item.searchType === SysModels.MartenTypeEnum.Invoices && (
        <InvoiceViewDialog
          key={item.sqlId}
          invoiceId={item.sqlId}
          customerNumber={item.customerNumber}
          onClose={() => {
            systemStore.setSearchedItemToPreview(undefined);
          }}
        ></InvoiceViewDialog>
      )}
      {!!item && item.searchType === SysModels.MartenTypeEnum.CreditMemos && (
        <CreditMemoViewDialog
          key={item.sqlId}
          memoId={item.sqlId}
          customerNumber={item.customerNumber}
          onClose={() => {
            systemStore.setSearchedItemToPreview(undefined);
          }}
        ></CreditMemoViewDialog>
      )}
      {!!item && item.searchType === SysModels.MartenTypeEnum.SalesOrders && (
        <OrderDetailDialog
          key={item.sqlId}
          customerNumber={
            commonService.isEmployeeSite ? item.customerNumber : undefined
          }
          order={
            {
              nsSqlId: item.sqlId,
            } as any
          }
          onClose={() => {
            systemStore.setSearchedItemToPreview(undefined);
          }}
        ></OrderDetailDialog>
      )}
      {!!item &&
        item.searchType === SysModels.MartenTypeEnum.InventoryItems && (
          <CatalogItemDialog
            key={item.id}
            itemId={item.id}
            customerNumber={item.customerNumber}
            onClose={() => {
              systemStore.setSearchedItemToPreview(undefined);
            }}
          ></CatalogItemDialog>
        )}
      {!!item && item.searchType === SysModels.MartenTypeEnum.HowTos && (
        <ViewHowToDialog
          key={item.id}
          id={item.id}
          onClose={() => {
            systemStore.setSearchedItemToPreview(undefined);
          }}
        ></ViewHowToDialog>
      )}
      {!!item &&
        item.searchType === SysModels.MartenTypeEnum.PortalDocumentations && (
          <ViewHowToDialog
            key={item.id}
            id={item.id}
            portalDoc={true}
            onClose={() => {
              systemStore.setSearchedItemToPreview(undefined);
            }}
          ></ViewHowToDialog>
        )}
    </>
  );
}

export default GlobalSearchItemViewer;
