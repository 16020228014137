import React, { useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import systemStore, { useCurrentCustomer } from "../stores/SystemStore";
import { reaction } from "mobx";
import commonService from "../services/CommonService";
import SysModels from "../models";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import { useNavigate } from "react-router-dom";

export const GLOBAL_SEARCH_FILTER = [
  {
    value: SysModels.MartenTypeEnum.CreditMemos,
    label: "Credit Memos",
    label2: "Credit Memo",
  },
  {
    value: SysModels.MartenTypeEnum.Customers,
    label: "Customers",
    label2: "Customer",
  },
  {
    value: SysModels.MartenTypeEnum.HowTos,
    label: "How To's",
    label2: "How To",
  },
  {
    value: SysModels.MartenTypeEnum.InventoryItems,
    label: "Inventory Items",
    label2: "Inventory Item",
  },
  {
    value: SysModels.MartenTypeEnum.Invoices,
    label: "Invoices",
    label2: "Invoice",
  },
  {
    value: SysModels.MartenTypeEnum.PortalDocumentations,
    label: "Portal Documentations",
    label2: "Portal Documentation",
  },
  {
    value: SysModels.MartenTypeEnum.SalesOrders,
    label: "Sales Orders",
    label2: "Sales Order",
  },
];

export const GLOBAL_SEARCH_FILTER_EMPSITE = [
  SysModels.MartenTypeEnum.Customers,
  SysModels.MartenTypeEnum.PortalDocumentations,
];

export const GetAvailableFilters = () => {
  if (commonService.isEmployeeSite && !systemStore.currentCustomer) {
    return [...GLOBAL_SEARCH_FILTER].filter(
      (f) => f.value !== SysModels.MartenTypeEnum.HowTos
    );
  } else {
    return [
      ...GLOBAL_SEARCH_FILTER.filter(
        (f) => !GLOBAL_SEARCH_FILTER_EMPSITE.includes(f.value)
      ),
    ];
  }
};

export const NavigateToGlobalSearchItem = (
  item: SysModels.IGlobalSearchOutputDto
) => {
  if (item.searchType === SysModels.MartenTypeEnum.Customers) {
    return;
  }
};

function SearchBar(props: {
  forPage?: boolean;
  query?: string;
  initialTypes?: string; //CSV
  onSearch?: (search: string) => void;
  onFilter?: (filters: SysModels.ILookupIntDto[]) => void;
}) {
  const navigate = useNavigate();
  const [showDd, setShowDd] = useState<boolean>();
  const [filters, setFilters] = useState<SysModels.ILookupIntDto[]>([]);
  const [loginStatus, setLoginStatus] = useState(systemStore.loginStatus);
  const [mwfRole, setMwfRole] = useState(systemStore.extension_MWFRole);

  const [highlight, setHighlight] = useState(false);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.loginStatus,
      (val, prevVal, r) => {
        setLoginStatus(val);
      }
    );
    const disposer2 = reaction(
      () => systemStore.extension_MWFRole,
      (n, p, r) => {
        setMwfRole(n);
      }
    );

    setFilters(GetAvailableFilters());

    const onKeydown = (e: KeyboardEvent) => {
      if (document.querySelectorAll(".modal-backdrop").length > 0) {
        return;
      }
      const el = e.target as HTMLElement;
      //console.log(el.tagName);

      const ignoreTags = ["INPUT", "TEXTAREA"];
      if (ignoreTags.includes(el.tagName)) {
        return;
      }

      if (e.key === "/") {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        setHighlight(true);
        commonService.focusInput(inputId, 300);
        setTimeout(() => {
          setHighlight(false);
        }, 1500);
        return false;
      }
    };
    window.addEventListener("keydown", onKeydown);

    return () => {
      disposer();
      disposer2();
      window.removeEventListener("keydown", onKeydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentCustomer = useCurrentCustomer();
  useEffect(() => {
    setSearch("");
    gridAllOthers.setData(undefined);
    gridInvoices.setData(undefined);
    gridOrders.setData(undefined);
    setFilters(GetAvailableFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomer]);

  const showSearch = useMemo(() => {
    if (!loginStatus.loggedIn || !loginStatus.canAccessWebsite) {
      return false;
    }
    if (loginStatus.mustDoSomething) {
      return (
        !loginStatus.mustDoSomething.changePassword &&
        !loginStatus.mustDoSomething.need2faAuthentication &&
        !loginStatus.mustDoSomething.shouldPickCustomer
      );
    }
    if (commonService.isEmployeeSite) {
      if (
        mwfRole & SysModels.UserRolesEnum.CustomerService ||
        mwfRole & SysModels.UserRolesEnum.Executive ||
        mwfRole & SysModels.UserRolesEnum.OeManager ||
        mwfRole & SysModels.UserRolesEnum.Sales ||
        mwfRole & SysModels.UserRolesEnum.SalesManager
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus, mwfRole]);

  const [showDdResult, setShowDdResult] = useState(false);
  const [search, setSearch] = useState("");

  const gridAllOthers = useFetchHelper(async () => {
    const list = filters.map((x) => x.value);
    const searchData: SysModels.IGlobalSearchInputDto = {
      query: search,
      isCreditMemos: list.includes(SysModels.MartenTypeEnum.CreditMemos),
      isCustomers: list.includes(SysModels.MartenTypeEnum.Customers),
      isInventoryItems: list.includes(SysModels.MartenTypeEnum.InventoryItems),
      isInvoices: false,
      isOrders: false,
      isHowTos: true,
      isPortalDocumentations: true,
    };
    return SysServices.http.search.globalSearchPreview(searchData);
  }, "Search Results");

  const gridInvoices = useFetchHelper(async () => {
    const searchData: SysModels.IGlobalSearchInputDto = {
      query: search,
      isCreditMemos: false,
      isCustomers: false,
      isInventoryItems: false,
      isInvoices: true,
      isOrders: false,
      isHowTos: false,
      isPortalDocumentations: false,
    };
    return SysServices.http.search.globalSearchPreview(searchData);
  }, "Search Invoices");

  const gridOrders = useFetchHelper(async () => {
    const searchData: SysModels.IGlobalSearchInputDto = {
      query: search,
      isCreditMemos: false,
      isCustomers: false,
      isInventoryItems: false,
      isInvoices: false,
      isOrders: true,
      isHowTos: false,
      isPortalDocumentations: false,
    };
    return SysServices.http.search.globalSearchPreview(searchData);
  }, "Search Orders");

  const [queStarted, setQueStarted] = useState(false);
  const validateSearch = () => {
    const list = filters.map((x) => x.value);
    gridAllOthers.setData(undefined);
    gridInvoices.setData(undefined);
    gridOrders.setData(undefined);

    const que: { getData: () => Promise<any> }[] = [];

    if (
      list.includes(SysModels.MartenTypeEnum.CreditMemos) ||
      list.includes(SysModels.MartenTypeEnum.Customers) ||
      list.includes(SysModels.MartenTypeEnum.InventoryItems)
    ) {
      //gridAllOthers.getData();
      que.push(gridAllOthers);
    }
    if (list.includes(SysModels.MartenTypeEnum.Invoices)) {
      //gridInvoices.getData();
      que.push(gridInvoices);
    }
    if (list.includes(SysModels.MartenTypeEnum.SalesOrders)) {
      //gridOrders.getData();
      que.push(gridOrders);
    }

    setQueStarted(true);
    setHas408({ other: false, order: false, invoice: false });
    const callData = (i: number) => {
      if (que[i]) {
        que[i].getData().finally(() => {
          callData(i + 1);
        });
      } else {
        setQueStarted(false);
      }
    };
    callData(0);
  };

  const doSearch = () => {
    if (props.forPage && props.onSearch) {
      props.onSearch(search);
      return null;
    }
    const tmo = setTimeout(() => {
      if (search) {
        validateSearch();
      }
    }, 500);
    return tmo;
  };

  const doFilter = () => {
    if (props.forPage && props.onFilter) {
      return setTimeout(() => {
        setShowDd(undefined);
        props.forPage && props.onFilter && props.onFilter(filters);
      }, 2000); //2sec delay before searching and closing menu
    }
    const tmo = setTimeout(() => {
      if (search) {
        validateSearch();
      }
    }, 1000);
    return tmo;
  };

  useEffect(() => {
    const tmo = doSearch();
    return () => {
      tmo && clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const tmo = doFilter();
    return () => {
      tmo && clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    // console.log(props.query);
    if (props.query && !commonService.isNullOrEmpty(props.query)) {
      setSearch(props.query);
    }
    if (
      props.initialTypes &&
      !commonService.isNullOrEmpty(props.initialTypes)
    ) {
      const types = `${props.initialTypes || ""}`
        .split(",")
        .filter((v) => !!Number(v))
        .map((v) => Number(v));

      if (types.length > 0 && GetAvailableFilters().length > types.length) {
        const filteredTypes = GetAvailableFilters().filter((at) =>
          types.find((st) => at.value === st)
        );
        if (filteredTypes.length > 0) {
          setFilters(filteredTypes);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.query, props.initialTypes]);

  const getDescription = (item: SysModels.IGlobalSearchOutputDto) => {
    if (
      item.searchType === SysModels.MartenTypeEnum.Customers ||
      item.searchType === SysModels.MartenTypeEnum.InventoryItems
    ) {
      return `${item.name} (${item.id})`;
    }
    return `${item.id} (${item.name})`;
  };

  const isUnderMaintenance = (type: SysModels.MartenTypeEnum) => {
    if (!filters.find((f) => f.value === type)) {
      return false;
    }
    if (type === SysModels.MartenTypeEnum.CreditMemos) {
      return gridAllOthers.data?.downSearchesDto?.isCreditMemoDown || false;
    }
    if (type === SysModels.MartenTypeEnum.Customers) {
      return gridAllOthers.data?.downSearchesDto?.isCustomerDown || false;
    }
    if (type === SysModels.MartenTypeEnum.InventoryItems) {
      return gridAllOthers.data?.downSearchesDto?.isInventoryItemsDown || false;
    }
    if (type === SysModels.MartenTypeEnum.Invoices) {
      return gridInvoices.data?.downSearchesDto?.isInvoiceDown || false;
    }
    if (type === SysModels.MartenTypeEnum.SalesOrders) {
      return gridOrders.data?.downSearchesDto?.isSalesOrdersDown || false;
    }
    return false;
  };

  const mainGrid = useMemo(() => {
    return [
      ...(gridAllOthers.data?.globalSearchOutputDtos || []),
      ...(gridInvoices.data?.globalSearchOutputDtos || []),
      ...(gridOrders.data?.globalSearchOutputDtos || []),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridAllOthers.data, gridInvoices.data, gridOrders.data]);

  const loadingStatus = useMemo(() => {
    if (
      gridAllOthers.status === FetchStatus.InProgress ||
      gridInvoices.status === FetchStatus.InProgress ||
      gridOrders.status === FetchStatus.InProgress
    ) {
      return FetchStatus.InProgress;
    }
    if (
      gridAllOthers.status === FetchStatus.Failed ||
      gridInvoices.status === FetchStatus.Failed ||
      gridOrders.status === FetchStatus.Failed
    ) {
      return FetchStatus.Failed;
    }
    if (
      gridAllOthers.status === FetchStatus.Complete ||
      gridInvoices.status === FetchStatus.Complete ||
      gridOrders.status === FetchStatus.Complete
    ) {
      return FetchStatus.Complete;
    }
    return FetchStatus.Default;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridAllOthers.status, gridInvoices.status, gridOrders.status]);

  const [has408, setHas408] = useState({
    other: false,
    invoice: false,
    order: false,
  });

  useEffect(() => {
    setHas408((p) => {
      return { ...p, other: gridAllOthers.error?.code === 408 };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridAllOthers.error]);

  useEffect(() => {
    setHas408((p) => {
      return { ...p, invoice: gridInvoices.error?.code === 408 };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridInvoices.error]);

  useEffect(() => {
    setHas408((p) => {
      return { ...p, order: gridOrders.error?.code === 408 };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridOrders.error]);

  const isInQue = (stat: FetchStatus) => {
    return (
      queStarted && stat !== FetchStatus.Complete && stat !== FetchStatus.Failed
    );
  };
  const [inputId] = useState(commonService.getUniqueId());

  return showSearch ? (
    <>
      <div
        className="flex flex-center"
        style={{
          border: highlight ? "solid 1px #555" : "solid 1px #ddd",
          paddingRight: "12px",
        }}
      >
        <div className="flex-1 flex flex-center px-1">
          <div>
            <Dropdown className="global-search-dropdown" show={showDd}>
              <Dropdown.Toggle
                id="global-search-dropdown"
                variant="light"
                size="sm"
              >
                <i className="fa fa-filter me-2"></i>
                <span>
                  Filter <small>({filters.length})</small>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {[...GetAvailableFilters()].map((f) => (
                  <div
                    key={f.value}
                    className="flex flex-center px-3 py-1 pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowDd(true);
                      setFilters((p) => {
                        if (!p.find((ff) => f.value === ff.value)) {
                          return [...p, f];
                        }
                        return p.filter((p) => p.value !== f.value);
                      });
                    }}
                  >
                    <div className="pe-2">
                      <i
                        className={`fa ${
                          filters.find((ff) => f.value === ff.value)
                            ? "fa-check-square"
                            : "fa-square-o"
                        }`}
                        style={{ fontWeight: "normal" }}
                      ></i>
                    </div>
                    <div className="no-wrap">{f.label}</div>
                  </div>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="global-search-input-container">
            {!props.forPage && (
              <section className="global-search-input-tooltip">
                Search <span>/</span>
              </section>
            )}
            <input
              autoFocus={props.forPage || false}
              id={inputId}
              type="text"
              className="form-control w-100"
              placeholder="Search"
              style={{
                border: "none",
              }}
              onFocus={(e) => setShowDdResult(true)}
              onBlur={(e) => {
                setTimeout(() => {
                  const $input = document.getElementById(inputId);
                  if ($input?.hasAttribute("ddkeepopen")) {
                    $input?.removeAttribute("ddkeepopen");
                    $input?.focus();
                    return;
                  }
                  setShowDdResult(false);
                }, 200);
              }}
              value={search}
              onChange={(e) => {
                const val = `${e.target.value || ""}`;
                setSearch(val);
              }}
            />
            {!props.forPage && (
              <div className={`dropdown-menu ${showDdResult ? "show" : ""}`}>
                {loadingStatus === FetchStatus.Complete && !mainGrid.length && (
                  <div className="dropdown-header">
                    <small>No Result(s)</small>
                  </div>
                )}

                {filters.length === 0 && (
                  <div className="dropdown-item dropdown-header disabled">
                    Pick a filter...
                  </div>
                )}

                {!!filters.find(
                  (x) => x.value === SysModels.MartenTypeEnum.Invoices
                ) && (
                  <>
                    {(isInQue(gridInvoices.status) ||
                      gridInvoices.status === FetchStatus.InProgress ||
                      (gridInvoices.status === FetchStatus.Default &&
                        search.length > 0)) && (
                      <>
                        <div className="dropdown-item dropdown-header disabled">
                          <small>Invoices</small>
                        </div>
                        <div className="dropdown-header">
                          <small>Loading...</small>
                        </div>
                      </>
                    )}
                    {has408.invoice && (
                      <>
                        <div className="dropdown-item dropdown-header disabled">
                          <small>Invoices</small>
                        </div>
                        <div className="dropdown-header">
                          <div className="flex flex-row">
                            <small className="flex-1 text-danger">
                              Server is busy...
                            </small>
                            <div
                              className="pointer"
                              onClick={(e) => {
                                document
                                  .getElementById(inputId)
                                  ?.setAttribute("ddkeepopen", "");
                                setHas408((p) => {
                                  return { ...p, invoice: false };
                                });
                                gridInvoices.getData();
                              }}
                            >
                              <i className="fa fa-refresh text-primary"></i>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

                {!!filters.find(
                  (x) => x.value === SysModels.MartenTypeEnum.SalesOrders
                ) && (
                  <>
                    {(isInQue(gridOrders.status) ||
                      gridOrders.status === FetchStatus.InProgress ||
                      (gridOrders.status === FetchStatus.Default &&
                        search.length > 0)) && (
                      <>
                        <div className="dropdown-item dropdown-header disabled">
                          <small>Sales Orders</small>
                        </div>
                        <div className="dropdown-header">
                          <small>Loading...</small>
                        </div>
                      </>
                    )}
                    {has408.order && (
                      <>
                        <div className="dropdown-item dropdown-header disabled">
                          <small>Sales Orders</small>
                        </div>
                        <div className="dropdown-header">
                          <div className="flex flex-row">
                            <small className="flex-1 text-danger">
                              Server is busy...
                            </small>
                            <div
                              className="pointer"
                              onClick={(e) => {
                                document
                                  .getElementById(inputId)
                                  ?.setAttribute("ddkeepopen", "");
                                setHas408((p) => {
                                  return { ...p, order: false };
                                });
                                gridOrders.getData();
                              }}
                            >
                              <i className="fa fa-refresh text-primary"></i>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

                {filters.filter((x) =>
                  [
                    SysModels.MartenTypeEnum.InventoryItems,
                    SysModels.MartenTypeEnum.CreditMemos,
                    SysModels.MartenTypeEnum.Customers,
                  ].includes(x.value)
                ).length > 0 && (
                  <>
                    {(isInQue(gridAllOthers.status) ||
                      gridAllOthers.status === FetchStatus.InProgress ||
                      (gridAllOthers.status === FetchStatus.Default &&
                        search.length > 0)) && (
                      <>
                        <div className="dropdown-item dropdown-header disabled">
                          <small>Others</small>
                        </div>
                        <div className="dropdown-header">
                          <small>Loading...</small>
                        </div>
                      </>
                    )}
                    {has408.other && (
                      <>
                        <div className="dropdown-item dropdown-header disabled">
                          <small>Others</small>
                        </div>
                        <div className="dropdown-header">
                          <div className="flex flex-row">
                            <small className="flex-1 text-danger">
                              Server is busy...
                            </small>
                            <div
                              className="pointer"
                              onClick={(e) => {
                                document
                                  .getElementById(inputId)
                                  ?.setAttribute("ddkeepopen", "");
                                setHas408((p) => {
                                  return { ...p, other: false };
                                });
                                gridAllOthers.getData();
                              }}
                            >
                              <i className="fa fa-refresh text-primary"></i>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

                {loadingStatus === FetchStatus.Failed &&
                  !has408.invoice &&
                  !has408.order &&
                  !has408.other && (
                    <div
                      className="dropdown-header pointer"
                      onClick={(e) => {
                        validateSearch();
                      }}
                    >
                      <small className="text-danger">Please Try Again</small>
                    </div>
                  )}
                {loadingStatus === FetchStatus.Default &&
                  search.length === 0 && (
                    <div className="dropdown-header">
                      <small>Start Typing</small>
                    </div>
                  )}

                {!!mainGrid.length && ( //gridAll.status === FetchStatus.Complete &&
                  <>
                    {filters
                      .filter((x) =>
                        mainGrid?.find(
                          (i) =>
                            isUnderMaintenance(x.value) ||
                            (i.searchType === x.value &&
                              !commonService.isNullOrWhitespace(i.name))
                        )
                      )
                      .sort(commonService.sortByStringProperty("label"))
                      .map((x) => (
                        <React.Fragment key={x.value}>
                          <div className="dropdown-item dropdown-header disabled">
                            <small>{x.label}</small>
                          </div>
                          {isUnderMaintenance(x.value) && (
                            <div className="dropdown-item dropdown-header disabled text-danger">
                              <small>Global Search Under Maintenance</small>
                            </div>
                          )}
                          {mainGrid
                            ?.filter(
                              (r) =>
                                r.searchType === x.value &&
                                !commonService.isNullOrWhitespace(r.name)
                            )
                            ?.map((r) => (
                              <div
                                key={r.id}
                                className="dropdown-item pointer"
                                style={{
                                  fontSize: "14px",
                                }}
                                onClick={(e) => {
                                  systemStore.setSearchedItemToPreview(r);
                                }}
                              >
                                {getDescription(r)}
                              </div>
                            ))}
                        </React.Fragment>
                      ))}
                  </>
                )}
                {loadingStatus === FetchStatus.Complete &&
                  !!mainGrid?.length && (
                    <>
                      <div className="dropdown-divider"></div>
                      <div
                        className="dropdown-item pointer"
                        onClick={(e) => {
                          if (GetAvailableFilters().length === filters.length) {
                            navigate(`/Search/${encodeURI(search)}`);
                          } else {
                            navigate(
                              `/Search/${encodeURI(search)}/${filters
                                .map((t) => t.value)
                                .sort()
                                .join(",")}`
                            );
                          }
                        }}
                      >
                        <small>View All...</small>
                      </div>
                    </>
                  )}
              </div>
            )}
          </div>
        </div>
        <div>
          <i className="fa fa-search"></i>
        </div>
      </div>
      <div className="flex-1 px-4"></div>
    </>
  ) : (
    <></>
  );
}

export default SearchBar;
