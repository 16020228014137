import SysModels from "../models";
import systemStore from "../stores/SystemStore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Dashboards
import CustomerDashboard from "../pages/CustomerDashboard";
import OeManagerDashboard from "../pages-employee/dashboards/OeManagerDashboard";
import ExecutiveDashboard from "../pages-employee/dashboards/ExecutiveDashboard";
import AdminDashboard from "../pages-employee/dashboards/AdminDashboard";
import AppAdminDashboard from "../pages-employee/dashboards/AppAdminDashboard";
import CustomerServiceDashboard from "../pages-employee/dashboards/CustomerServiceDashboard";
import DocumentManagerDashboard from "../pages-employee/dashboards/DocumentManagerDashboard";

//Pages
import UserEdit from "../pages-employee/UserEdit";
import Users from "../pages-employee/Users";
import IncomingOrdersInError from "../pages-employee/IncomingOrdersInError";
import Customers from "../pages-employee/Customers";
import TopCustomers from "../pages-employee/TopCustomers";
import BillingCenter from "../pages/BillingCenter";
import CustomerService from "../pages/CustomerService";
import OrderGuide from "../pages/OrderGuide";
import MyOrders from "../pages/MyOrders";
import PrintGuide from "../pages/PrintGuide";
import CustomerDocumentation from "../pages/CustomerDocumentation";

import IncomingOrders from "../pages-employee/IncomingOrders";
import BatchRequestInvoices from "../pages-employee/BatchRequestInvoices";
import MWFSettings from "../pages-employee/MWFSettings";
import SiteAnnouncements from "../pages-employee/SiteAnnouncements";
import NonDeliveryDates from "../pages-employee/NonDeliveryDates";
import InventoryItems from "../pages-employee/InventoryItems";
import CustomerSurveys from "../pages-employee/CustomerSurveys";
import OutgoingInvoices from "../pages-employee/OutgoingInvoices";
import CustomerDocumentationDetail from "../pages/CustomerDocumentationDetail";
import ConfigureAzureTable from "../pages-employee/ConfigureAzureTable";
import SalesManagerDashboard from "../pages-employee/dashboards/SalesManagerDashboard";
import SalesRepDashboard from "../pages-employee/dashboards/SalesRepDashboard";
import MySalesSummary from "../pages-employee/MySalesSummary";
import SalesRepSummary from "../pages-employee/SalesRepSummary";
import SalesRepSalesOrder from "../pages-employee/SalesRepSalesOrder";
import FTPManagement from "../pages-employee/FTPManagement";
import SalesRepIndividualSummary from "../pages-employee/SalesRepIndividualSummary";
import ReportMaintenance from "../pages-employee/ReportMaintenance";
import ReportsByRole from "../pages-employee/ReportsByRole";
import ReportTestPage from "../pages-employee/report-views/ReportTestPage";
import ActiveOnLineCustomersReport from "../pages-employee/report-views/ActiveOnLineCustomersReport";
import InactiveOnLineCustomersReport from "../pages-employee/report-views/InactiveOnLineCustomersReport";
import SpendBySourceReport from "../pages-employee/report-views/SpendBySourceReport";
import InitialUsers from "../pages-employee/InitialUsers";
import InitialUserEdit from "../pages-employee/InitialUserEdit";
import ProductCatalog from "../pages/ProductCatalog";
import CustomerDashboardList from "../pages-employee/CustomerDashboardList";
import CustomerDashboardEdit from "../pages-employee/CustomerDashboardEdit";
import ProductDetail from "../pages/ProductDetail";
import BidSheetOrder from "../pages-employee/BidSheetOrder";
import LocalSpendBySourceSummaryReport from "../pages-employee/report-views/LocalSpendBySourceSummaryReport";
import toastStore from "../stores/ToastStore";
import TotalSpendBySourceReport from "../pages-employee/report-views/TotalSpendBySourceReport";
import TotalSpendBySourceSummaryReport from "../pages-employee/report-views/TotalSpendBySourceSummaryReport";
import ReleaseNotes from "../pages-employee/ReleaseNotes";
import PublishedReleaseNotes from "../pages/PublishedReleaseNotes";
import PublishedReleaseNotesDetail from "../pages/PublishedReleaseNotesDetail";
import OpenOrdersReport from "../pages-employee/report-views/OpenOrdersReport";
import MonitorRuleList from "../pages-employee/MonitorRuleList";
import SiteDownConfiguration from "../pages-employee/SiteDownConfiguration";
import ManagePortalErrors from "../pages-employee/ManagePortalErrors";
import BuyingGroupStorage from "../pages-employee/BuyingGroupStorage";
import CustomerLastLogins from "../pages-employee/CustomerLastLogins";
import PaidInvoices from "../pages-employee/PaidInvoices";
import ApiIntegrationPage from "../pages-employee/ApiIntegrationPage";
import SalesRepSalesProjection from "../pages-employee/SalesRepSalesProjection";
import PurchasingDashboard from "../pages-employee/dashboards/PurchasingDashboard";
import PurchaseOrders from "../pages-employee/PurchaseOrders";
import VendorsPage from "../pages-employee/VendorsPage";
import VendorDetailPage from "../pages-employee/VendorDetailPage";
import LoginAsSalesRepDashboard from "../pages-employee/dashboards/LoginAsSalesRepDashboard";
import SkuMapping from "../pages-employee/SkuMapping";

const AllRoles =
  SysModels.UserRolesEnum.Admin +
  SysModels.UserRolesEnum.Executive +
  SysModels.UserRolesEnum.OeManager +
  SysModels.UserRolesEnum.CustomerService +
  SysModels.UserRolesEnum.Sales +
  SysModels.UserRolesEnum.SalesManager +
  SysModels.UserRolesEnum.DocumentManager +
  SysModels.UserRolesEnum.ApplicationAdmin +
  SysModels.UserRolesEnum.Purchasing +
  SysModels.UserRolesEnum.LoginAsSalesRep;

const CustomerSiteRoles =
  SysModels.UserRolesEnum.Executive +
  SysModels.UserRolesEnum.CustomerService +
  SysModels.UserRolesEnum.OeManager +
  SysModels.UserRolesEnum.Sales +
  SysModels.UserRolesEnum.SalesManager;

export interface IMenuAction {
  label: string;
  icon?: string;
  url: string;
  role: number;
  hidden?: boolean;
  component?: any;
  dashboard?: boolean;
  asCustomer?: boolean;
  canAccess?: () => boolean;
}

class MenuActions {
  getDashboardUrl = (role: SysModels.UserRolesEnum) => {
    return this.actions.find((x) => x.dashboard && x.role === role)?.url || "/";
  };

  canAccessSalesRepSummary = () => {
    if (systemStore.activeRole & SysModels.UserRolesEnum.Executive) {
      return true;
    }
    if (systemStore.activeRole & SysModels.UserRolesEnum.SalesManager) {
      return true;
    }
    if (
      systemStore.extension_MWFRole & SysModels.UserRolesEnum.LoginAsSalesRep &&
      systemStore.salesAccountViewing &&
      !systemStore.currentCustomer
    ) {
      return systemStore.salesAccountViewing.role === "Sales Manager";
    }
    return false;
  };

  actions: IMenuAction[] = [
    //Dashboards
    {
      label: "Admin",
      url: "/admindashboard",
      icon: "zmdi-shield-security",
      role: SysModels.UserRolesEnum.Admin,
      dashboard: true,
      component: AdminDashboard,
    },
    {
      label: "Application Admin",
      url: "/appadmindashboard",
      icon: "zmdi-wrench",
      role: SysModels.UserRolesEnum.ApplicationAdmin,
      dashboard: true,
      component: AppAdminDashboard,
    },
    {
      label: "Customer Service",
      url: "/customerservicedashboard",
      icon: "zmdi-accounts-list-alt",
      role: SysModels.UserRolesEnum.CustomerService,
      dashboard: true,
      component: CustomerServiceDashboard,
    },
    {
      label: "Document Manager",
      url: "/documentmanagerdashboard",
      icon: "zmdi-file-text",
      role: SysModels.UserRolesEnum.DocumentManager,
      dashboard: true,
      component: DocumentManagerDashboard,
    },
    {
      label: "Executive",
      url: "/executivedashboard",
      icon: "zmdi-badge-check",
      role: SysModels.UserRolesEnum.Executive,
      dashboard: true,
      component: ExecutiveDashboard,
    },
    {
      label: "OE Manager",
      url: "/oemanagerdashboard",
      icon: "zmdi-tab",
      role: SysModels.UserRolesEnum.OeManager,
      dashboard: true,
      component: OeManagerDashboard,
    },
    {
      label: "Sales",
      url: "/salesdashboard",
      icon: "zmdi-money",
      role: SysModels.UserRolesEnum.Sales,
      dashboard: true,
      component: SalesRepDashboard,
    },
    {
      label: "Sales Manager",
      url: "/salesmanagerdashboard",
      icon: "zmdi-money-box",
      role: SysModels.UserRolesEnum.SalesManager,
      dashboard: true,
      component: SalesManagerDashboard,
    },
    {
      label: "Purchasing",
      url: "/purchasingdashboard",
      icon: "zmdi-receipt",
      role: SysModels.UserRolesEnum.Purchasing,
      dashboard: true,
      component: PurchasingDashboard,
    },
    {
      label: "Login As Sales", //For Sales Rep and Sales Manager now
      url: "/loginassales",
      icon: "zmdi-account",
      role: SysModels.UserRolesEnum.LoginAsSalesRep,
      dashboard: true,
      component: LoginAsSalesRepDashboard,
    },

    //ACTIONS
    {
      label: "Purchase Orders",
      url: "/purchaseorders",
      icon: "zmdi-receipt",
      role: SysModels.UserRolesEnum.Purchasing,
      component: PurchaseOrders,
    },
    {
      label: "Vendors",
      url: "/vendors",
      icon: "zmdi-store",
      role: SysModels.UserRolesEnum.Purchasing,
      component: VendorsPage,
    },
    {
      label: "Vendors",
      url: "/vendors/:id",
      icon: "zmdi-store",
      role: SysModels.UserRolesEnum.Purchasing,
      hidden: true,
      component: VendorDetailPage,
    },
    {
      label: "Customers",
      url: "/customers",
      icon: "zmdi-accounts-alt",
      role: CustomerSiteRoles,
      component: Customers,
    },
    // {
    //   label: "Sales Orders",
    //   url: "/salesorders",
    //   icon: "fa-file-o",
    //   role:
    //     SysModels.UserRolesEnum.CustomerService +
    //     SysModels.UserRolesEnum.Executive +
    //     SysModels.UserRolesEnum.OeManager,
    // },
    // {
    //   label: "Invoices",
    //   url: "/allinvoices",
    //   icon: "fa-file-text-o",
    //   role:
    //     SysModels.UserRolesEnum.CustomerService +
    //     SysModels.UserRolesEnum.Executive +
    //     SysModels.UserRolesEnum.OeManager,
    // },
    {
      label: "Incoming Orders",
      url: "/incomingorders",
      icon: "fa-cart-plus",
      role:
        SysModels.UserRolesEnum.CustomerService +
        SysModels.UserRolesEnum.OeManager +
        SysModels.UserRolesEnum.Sales +
        SysModels.UserRolesEnum.SalesManager,
      component: IncomingOrders,
    },
    {
      label: "Outgoing Invoices",
      url: "/outinvoices",
      icon: "zmdi-open-in-new",
      role:
        SysModels.UserRolesEnum.CustomerService +
        SysModels.UserRolesEnum.OeManager +
        SysModels.UserRolesEnum.Sales +
        SysModels.UserRolesEnum.SalesManager,
      component: OutgoingInvoices,
    },
    {
      label: "Incoming Orders In Error",
      url: "/incomingordersinerror",
      icon: "fa-cart-arrow-down",
      role:
        SysModels.UserRolesEnum.CustomerService +
        SysModels.UserRolesEnum.OeManager,
      component: IncomingOrdersInError,
    },
    {
      label: "Surveys",
      url: "/listsurveys/:token?",
      icon: "zmdi-comment-edit",
      role:
        SysModels.UserRolesEnum.Executive +
        SysModels.UserRolesEnum.OeManager +
        SysModels.UserRolesEnum.Sales +
        SysModels.UserRolesEnum.SalesManager,
      component: CustomerSurveys,
    },
    // {
    //   label: "System Activities",
    //   url: "/systemactivityadmin",
    //   icon: "fa-terminal",
    //   role: SysModels.UserRolesEnum.OeManager,
    // },
    {
      label: "Non-Delivery Dates",
      url: "/nondeliverydates",
      icon: "fa-calendar-times-o",
      role: SysModels.UserRolesEnum.OeManager + SysModels.UserRolesEnum.Admin,
      component: NonDeliveryDates,
    },
    {
      label: "Inventory Items",
      url: "/activatedeactivateinventoryitem",
      icon: "fa-tags",
      role: SysModels.UserRolesEnum.OeManager + SysModels.UserRolesEnum.Admin,
      component: InventoryItems,
    },
    {
      label: "Item Mapping", //for Buying Groups
      url: "/itemmapping",
      icon: "fa-table",
      role: SysModels.UserRolesEnum.OeManager + SysModels.UserRolesEnum.Admin,
      component: SkuMapping,
    },
    // {
    //   label: "SDX/PA Invoice Items",alooo
    //   url: "/sdxpainventoryitems",
    //   icon: "fa-tags",
    //   role: SysModels.UserRolesEnum.OeManager + SysModels.UserRolesEnum.Admin,
    // },
    // {
    //   label: "HC/PA Invoice Items",
    //   url: "/hcpainventoryitems",
    //   icon: "fa-tags",
    //   role: SysModels.UserRolesEnum.OeManager + SysModels.UserRolesEnum.Admin,
    // },
    {
      label: "Batch Request Invoices",
      url: "/batchrequestinvoices",
      icon: "zmdi-collection-bookmark",
      role: SysModels.UserRolesEnum.OeManager + SysModels.UserRolesEnum.Admin,
      component: BatchRequestInvoices,
    },
    {
      label: "Users",
      url: "/users",
      icon: "fa-address-card",
      role:
        SysModels.UserRolesEnum.Admin +
        SysModels.UserRolesEnum.ApplicationAdmin +
        SysModels.UserRolesEnum.OeManager,
      component: Users,
    },
    {
      label: "Users",
      url: "/users/:id",
      role:
        SysModels.UserRolesEnum.Admin +
        SysModels.UserRolesEnum.ApplicationAdmin +
        SysModels.UserRolesEnum.OeManager,
      component: UserEdit,
      hidden: true,
    },
    {
      label: "Initial Users",
      url: "/initialusers",
      icon: "fa-address-card-o",
      role:
        SysModels.UserRolesEnum.Admin +
        SysModels.UserRolesEnum.ApplicationAdmin,
      component: InitialUsers,
    },
    {
      label: "Initial Users",
      url: "/initialusers/:id",
      role:
        SysModels.UserRolesEnum.Admin +
        SysModels.UserRolesEnum.ApplicationAdmin,
      component: InitialUserEdit,
      hidden: true,
    },
    {
      label: "Customers Logins",
      url: "/customerlastlogins",
      icon: "zmdi-accounts-alt",
      role:
        SysModels.UserRolesEnum.Admin +
        SysModels.UserRolesEnum.ApplicationAdmin,
      component: CustomerLastLogins,
    },

    //APP ADMIN
    // {
    //   label: "Security Matrix",
    //   url: "/securitymatrix",
    //   icon: "fa-shield",
    //   role:
    //     SysModels.UserRolesEnum.ApplicationAdmin +
    //     SysModels.UserRolesEnum.Admin,
    // },
    // {
    //   label: "System Activities",
    //   url: "/systemactivity",
    //   icon: "fa-terminal",
    //   role:
    //     SysModels.UserRolesEnum.ApplicationAdmin +
    //     SysModels.UserRolesEnum.Admin,
    //   component: SystemActivity,
    // },
    {
      label: "Release/Doc Maint.",
      url: "/releasenotesmaintenance",
      icon: "fa-file-text-o",
      role: SysModels.UserRolesEnum.ApplicationAdmin,
      component: ReleaseNotes,
    },
    {
      label: "Report Maintenance",
      url: "/reportmaintenance",
      icon: "fa-list",
      role: SysModels.UserRolesEnum.ApplicationAdmin,
      component: ReportMaintenance,
    },
    {
      label: "Site Announcements",
      url: "/sitemessages",
      icon: "fa-bullhorn",
      role:
        SysModels.UserRolesEnum.ApplicationAdmin +
        SysModels.UserRolesEnum.Admin +
        SysModels.UserRolesEnum.OeManager,
      component: SiteAnnouncements,
    },
    {
      label: "Site Down",
      url: "/sitedownconfiguration",
      icon: "fa-warning",
      role: SysModels.UserRolesEnum.ApplicationAdmin,
      component: SiteDownConfiguration,
    },
    {
      label: "MWF Settings",
      url: "/mwfsettings",
      icon: "fa-circle",
      role:
        SysModels.UserRolesEnum.ApplicationAdmin +
        SysModels.UserRolesEnum.Admin,
      component: MWFSettings,
    },
    // {
    //   label: "Batch Job Schedules",
    //   url: "/batchjobschedules",
    //   icon: "fa-tasks",
    //   role:
    //     SysModels.UserRolesEnum.ApplicationAdmin +
    //     SysModels.UserRolesEnum.Admin,
    //   component: BatchScheduleManager,
    // },
    {
      label: "Azure Configuration",
      url: "/azureconfiguration",
      icon: "fa-table",
      role:
        SysModels.UserRolesEnum.ApplicationAdmin +
        SysModels.UserRolesEnum.Admin,
      component: ConfigureAzureTable,
    },
    {
      label: "FTP Management",
      url: "/ftpmanagement",
      icon: "fa-plug",
      role:
        SysModels.UserRolesEnum.ApplicationAdmin +
        SysModels.UserRolesEnum.Admin,
      component: FTPManagement,
    },
    {
      label: "Manage Monitor Rules",
      url: "/managemonitorrules",
      icon: "fa-television",
      role: SysModels.UserRolesEnum.ApplicationAdmin,
      component: MonitorRuleList,
    },
    {
      label: "Manage Portal Errors",
      url: "/manageportalerrors",
      icon: "fa-exclamation-circle",
      role: SysModels.UserRolesEnum.ApplicationAdmin,
      component: ManagePortalErrors,
    },

    {
      label: "Buying Group Storage",
      url: "/buyinggroupstorage",
      icon: "fa-hdd-o",
      role:
        SysModels.UserRolesEnum.ApplicationAdmin +
        SysModels.UserRolesEnum.Admin,
      component: BuyingGroupStorage,
    },
    {
      label: "API Integrations",
      url: "/api-integrations",
      icon: "fa-plug",
      role:
        SysModels.UserRolesEnum.ApplicationAdmin +
        SysModels.UserRolesEnum.Admin,
      component: ApiIntegrationPage,
    },
    /////DISABLED FOR NOW, IN-PROGRESS
    // {
    //   label: "Azure Function Schedule",
    //   url: "/azurefunctionschedule",
    //   icon: "fa-calendar",
    //   role: SysModels.UserRolesEnum.ApplicationAdmin,
    //   component: AzureFunctionSchedule,
    // },

    //ADMIN
    // {
    //   label: "Master Accounts",
    //   url: "/masteraccounts",
    //   icon: "fa-user-secret",
    //   role: SysModels.UserRolesEnum.Admin + SysModels.UserRolesEnum.Executive,
    // },

    //EXECUTIVE
    {
      label: "Top Customers",
      url: "/topcustomers",
      icon: "zmdi-assignment-account",
      role:
        SysModels.UserRolesEnum.Executive +
        SysModels.UserRolesEnum.Sales +
        SysModels.UserRolesEnum.SalesManager,
      component: TopCustomers,
    },
    {
      label: "Sales Rep Summary",
      url: "/salesrepsummary",
      icon: "fa-users",
      role:
        SysModels.UserRolesEnum.Executive +
        SysModels.UserRolesEnum.SalesManager +
        SysModels.UserRolesEnum.Sales, //This is LoginAsSales but filtered by Sales Manager
      component: SalesRepSummary,
      canAccess: () => this.canAccessSalesRepSummary(),
    },
    {
      label: "Sales Rep Summary",
      url: "/salesrepdetail/:email",
      icon: "fa-users",
      role:
        SysModels.UserRolesEnum.Executive +
        SysModels.UserRolesEnum.SalesManager +
        SysModels.UserRolesEnum.Sales, //This is LoginAsSales but filtered by Sales Manager
      hidden: true,
      component: SalesRepIndividualSummary,
      canAccess: () => this.canAccessSalesRepSummary(),
    },
    {
      label: "Online Paid Invoices",
      url: "/paidinvoices",
      icon: "fa-credit-card",
      role: SysModels.UserRolesEnum.Executive,
      component: PaidInvoices,
      canAccess: () => {
        return systemStore.allowInvoicePayment;
      },
    },
    // {
    //   label: "Sales Rep Detail",
    //   url: "/salesrepdetail/:id",
    //   icon: "fa-users",
    //   role: SysModels.UserRolesEnum.Executive,
    //   component: MySalesSummary,
    //   hidden: true,
    // },

    //SALES REP
    {
      label: "My Sales Summary",
      url: "/mysalessummary",
      icon: "fa-money",
      role:
        SysModels.UserRolesEnum.Sales + SysModels.UserRolesEnum.SalesManager,
      component: MySalesSummary,
    },
    {
      label: "Sales Orders",
      url: "/salesorders",
      icon: "zmdi-truck",
      role:
        SysModels.UserRolesEnum.Sales + SysModels.UserRolesEnum.SalesManager,
      component: SalesRepSalesOrder,
    },
    {
      label: "Sales Projection",
      url: "/salesprojection/:worder?/:wperiod?",
      icon: "fa-line-chart",
      role:
        SysModels.UserRolesEnum.Sales + SysModels.UserRolesEnum.SalesManager,
      component: SalesRepSalesProjection,
    },

    //CUSTOMER SITE
    {
      label: "Billing Center",
      url: "/billing-center",
      icon: "zmdi-book",
      role: CustomerSiteRoles,
      asCustomer: true,
      component: BillingCenter,
    },
    {
      label: "Billing Center",
      url: "/billing-center/:tab",
      icon: "zmdi-book",
      role: CustomerSiteRoles,
      asCustomer: true,
      hidden: true,
      component: BillingCenter,
    },
    {
      label: "Customer Service",
      url: "/customer-service",
      icon: "zmdi-flag",
      role: CustomerSiteRoles,
      asCustomer: true,
      component: CustomerService,
    },
    {
      label: "Place an Order",
      url: "/order-guide",
      icon: "zmdi-collection-text",
      role: CustomerSiteRoles,
      asCustomer: true,
      component: OrderGuide,
      canAccess: () => {
        return systemStore.allowOrderGuide;
      },
    },
    {
      label: "Customer Orders",
      url: "/my-orders",
      icon: "zmdi-truck",
      role: CustomerSiteRoles,
      asCustomer: true,
      component: MyOrders,
    },
    {
      label: "Print Guide",
      url: "/print-guide",
      role: CustomerSiteRoles,
      asCustomer: true,
      hidden: true,
      component: PrintGuide,
    },
    {
      label: "Customer Dashboard",
      url: "/customerdashboard",
      role: CustomerSiteRoles,
      asCustomer: true,
      hidden: true,
      component: CustomerDashboard,
    },
    {
      label: "Bid Sheet Order",
      url: "/bidsheetorder",
      icon: "zmdi-book",
      role: CustomerSiteRoles,
      asCustomer: true,
      component: BidSheetOrder,
    },

    //HOW-To
    {
      label: "Customer How To",
      url: "/how-to",
      role: AllRoles,
      hidden: true,
      component: CustomerDocumentation,
    },
    {
      label: "Customer How To",
      url: "/how-to",
      icon: "zmdi-help",
      role: AllRoles,
      asCustomer: true,
      component: CustomerDocumentation,
    },
    {
      label: "Customer How To",
      url: "/how-to/:id",
      role: AllRoles,
      hidden: true,
      component: CustomerDocumentationDetail,
    },

    //PRODUCT CATALOG
    {
      label: "Product Catalog",
      url: "/catalog",
      icon: "zmdi-shopping-cart",
      role: CustomerSiteRoles,
      asCustomer: true,
      component: ProductCatalog,
    },
    {
      label: "Product Catalog",
      url: "/catalog/:category",
      icon: "zmdi-shopping-cart",
      role: CustomerSiteRoles,
      asCustomer: true,
      hidden: true,
      component: ProductCatalog,
    },
    {
      label: "Product Catalog (Featured)",
      url: "/catalog/:featured/:category",
      icon: "zmdi-shopping-cart",
      role: CustomerSiteRoles,
      asCustomer: true,
      hidden: true,
      component: ProductCatalog,
    },
    {
      label: "Product Details",
      url: "/productdetail/:id",
      icon: "zmdi-shopping-cart",
      role: CustomerSiteRoles,
      asCustomer: true,
      hidden: true,
      component: ProductDetail,
    },
    // {
    //   label: "Manage Catalog",
    //   url: "/managecatalog",
    //   icon: "zmdi-labels",
    //   role: SysModels.UserRolesEnum.Admin,
    //   component: ManageCatalog,
    // },
    // {
    //   label: "Manage Catalog",
    //   url: "/managecatalog/:id",
    //   icon: "zmdi-labels",
    //   role: SysModels.UserRolesEnum.Admin,
    //   hidden: true,
    //   component: EditProductCatalog,
    // },
    {
      label: "Customer Dashboard",
      url: "/managedashboard",
      icon: "zmdi-view-dashboard",
      role: SysModels.UserRolesEnum.DocumentManager,
      component: CustomerDashboardList,
    },
    {
      label: "Customer Dashboard",
      url: "/managedashboard/:id",
      icon: "zmdi-view-dashboard",
      role: SysModels.UserRolesEnum.DocumentManager,
      hidden: true,
      component: CustomerDashboardEdit,
    },

    //REPORTS BY ROLE
    {
      label: "Reports",
      url: "/reports",
      icon: "fa-files-o",
      role: AllRoles - SysModels.UserRolesEnum.LoginAsSalesRep,
      component: ReportsByRole,
    },
    {
      label: "Release Notes",
      url: "/releasenotes",
      icon: "fa-book",
      role: AllRoles,
      component: PublishedReleaseNotes,
    },
    {
      label: "Release Notes",
      url: "/releasenotes",
      icon: "fa-book",
      role: AllRoles,
      component: PublishedReleaseNotes,
      asCustomer: true,
    },
    {
      label: "Release Notes",
      url: "/releasenotes/:id",
      icon: "fa-book",
      role: AllRoles,
      component: PublishedReleaseNotesDetail,
      hidden: true,
    },
    {
      label: "Portal Documentation",
      url: "/portaldocumentation",
      icon: "fa-book",
      role: AllRoles, //SysModels.UserRolesEnum.Admin + SysModels.UserRolesEnum.ApplicationAdmin,
      component: () => (
        <PublishedReleaseNotes portalDocument={true}></PublishedReleaseNotes>
      ),
    },
    {
      label: "Portal Documentation",
      url: "/portaldocumentation/:id",
      icon: "fa-book",
      role: AllRoles, //SysModels.UserRolesEnum.Admin + SysModels.UserRolesEnum.ApplicationAdmin,
      component: PublishedReleaseNotesDetail,
      hidden: true,
    },
    //ALL REPORT VIEWS
    ...[
      //START HERE-------------
      {
        url: "/reportview/ActiveOnLineCustomers/:id",
        component: ActiveOnLineCustomersReport,
      },
      {
        url: "/reportview/InactiveOnLineCustomers/:id",
        component: InactiveOnLineCustomersReport,
      },
      {
        url: "/reportview/LocalSpendBySource/:id",
        component: SpendBySourceReport,
      },
      {
        url: "/reportview/LocalSpendBySourceSummary/:id",
        component: LocalSpendBySourceSummaryReport,
      },
      {
        url: "/reportview/TotalSpendBySource/:id",
        component: TotalSpendBySourceReport,
      },
      {
        url: "/reportview/TotalSpendBySourceSummary/:id",
        component: TotalSpendBySourceSummaryReport,
      },
      {
        url: "/reportview/OpenOrders/:id",
        component: OpenOrdersReport,
      },
      {
        url: "/reportview/*",
        component: RedirectToReports,
      },
      //END ---------------------
    ].map((action) => {
      return {
        label: "Report View",
        icon: "fa-file",
        role: AllRoles,
        hidden: true,
        url: action.url,
        component: action.component,
      };
    }),

    //TEST REPORT
    {
      label: "Report View",
      url: "/reportview/testpage",
      icon: "fa-file",
      role: AllRoles,
      hidden: true,
      component: ReportTestPage,
    },
  ];
}

const RedirectToReports = (props: any) => {
  const navigate = useNavigate();
  useEffect(() => {
    toastStore.showToast("Report Page Not Found", "warning");
    navigate("/reports");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>...</>;
};

const menuActions = new MenuActions();
export default menuActions;
