import React, { useEffect, useState } from "react";
import FormModal from "../../components/common/FormModal";
import DocEditor from "../../components/common/DocEditor";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import SysServices from "../../services";
import CommonSpinner from "../../components/common/CommonSpinner";

function ViewHowToDialog(props: {
  id: any;
  onClose: () => void;
  portalDoc?: boolean;
}) {
  const docObj = useFetchHelper(async () => {
    if (props.portalDoc) {
      return SysServices.http.siteDocumentation.getPublished(props.id);
    }
    return SysServices.http.generalDocumentation.getPublished(props.id);
  }, "Document");

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (calledOnce) {
      return; //do nothing...
    }
    setCalledOnce(true);
    docObj.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calledOnce, docObj]);

  return (
    <FormModal
      title={docObj.data?.title || "Loading..."}
      isOpen={true}
      close={() => {
        props.onClose();
      }}
      submit={(e) => {
        props.onClose();
      }}
      primaryButtonOnly={true}
      submitButtonLabel="Done"
    >
      <div>
        <div className="px-4 py-3">
          {docObj.status === FetchStatus.InProgress ? (
            <>
              <CommonSpinner></CommonSpinner>
            </>
          ) : (
            <>
              <h4>{docObj.data?.subject || ""}</h4>
              <hr />
              <DocEditor
                readonly={true}
                content={docObj.data?.content || ""}
              ></DocEditor>
            </>
          )}
        </div>
      </div>
    </FormModal>
  );
}

export default ViewHowToDialog;
