import { useEffect } from "react";
import CommonSpinner from "../../components/common/CommonSpinner";
import SysModels from "../../models";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";

function IncomingOrderDetail(props: {
  id: number;
  customerId: string;
  onReady: (data: any) => void;
  isError?: boolean;
}) {
  const detail = useFetchHelper(async () => {
    let req: Promise<
      SysModels.IOrderInErrorOutputDto | SysModels.IOrderOutputDto
    >;
    if (props.isError) {
      req = SysServices.http.siteManagement.getOrderInErrorDetail(props.id);
    } else {
      req = SysServices.http.siteManagement.getIncomingOrder(
        props.customerId,
        props.id
      );
    }
    return req;
  }, "Incoming Order Details");

  useEffect(() => {
    detail.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (detail.status === FetchStatus.Complete && detail.data) {
      props.onReady(detail.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail.status, detail.data]);

  return (
    <>
      {detail.status === FetchStatus.InProgress && (
        <div>
          <CommonSpinner></CommonSpinner>
        </div>
      )}
      {detail.status === FetchStatus.Complete && (
        <div>
          <div className="row pb-3">
            <div className="col-sm-12 col-md-6">
              <table className="no-format">
                <tbody>
                  <tr>
                    <td className="pe-2 no-wrap">
                      <strong>Customer Name:</strong>
                    </td>
                    <td>
                      <span>{detail.data?.customerName}</span>
                    </td>
                  </tr>
                  {/* <tr>
                    <td className="pe-2 no-wrap">
                      <strong>Customer Number:</strong>
                    </td>
                    <td>
                      <span>{detail.data?.erpCustomerNumber}</span>
                    </td>
                  </tr> */}
                  <tr>
                    <td className="pe-2 no-wrap">
                      <strong>BG Order Number:</strong>
                    </td>
                    <td>
                      <span>
                        {props.isError
                          ? (detail.data as SysModels.IOrderInErrorOutputDto)
                              ?.externalOrderNo
                          : detail.data?.externalOrderNumber}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="pe-2">
                      <strong>Date In:</strong>
                    </td>
                    <td>
                      {detail.data?.placedOn &&
                        commonService.toLocalDate(
                          detail.data?.placedOn,
                          "date"
                        )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-12 col-md-6">
              <table className="no-format">
                <tbody>
                  <tr>
                    <td className="pe-2">
                      <strong>Requested For:</strong>
                    </td>
                    <td>
                      {detail.data?.deliveryDate &&
                        commonService.toLocalDate(
                          detail.data?.deliveryDate,
                          "date"
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td className="pe-2 no-wrap">
                      <strong>ERP Order Number:</strong>
                    </td>
                    <td>
                      <span>{detail.data?.erpOrderNumber}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="pe-2">
                      <strong>Total:</strong>
                    </td>
                    <td>
                      {commonService.toMoney(detail.data?.totalOrderAmount)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <table className="table table-striped table-sm w-100 bg-white">
            <thead>
              <tr>
                <th>Item Code</th>
                <th>Description</th>
                <th className="text-center">Quantity</th>
                <th className="text-right">Unit Price</th>
                <th className="text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              {(detail.data?.orderLineOutputDtos || []).map((item) => (
                <tr key={item.itemCode}>
                  <td>{item.itemCode}</td>
                  <td>{item.itemDescription}</td>
                  <td className="text-center">
                    {commonService.toNumberWithComma(item.quantity)}
                  </td>
                  <td className="text-right">
                    {commonService.toMoney(item.unitPrice)}
                  </td>
                  <td className="text-right">
                    {commonService.toMoney(item.totalPrice)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pb-4">
            <div>
              <strong>Comments</strong>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: props.isError
                  ? commonService.crToBr(
                      (detail.data as SysModels.IOrderInErrorOutputDto)
                        ?.errorDescription || ""
                    )
                  : commonService.crToBr(detail.data?.comment || ""),
              }}
            ></div>
          </div>
        </div>
      )}
    </>
  );
}

export default IncomingOrderDetail;
